var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var loopable = function loopable(i, length) {
  var props = {};

  var setProp = function setProp(name) {
    var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    props[name] = value;
  };

  i === 0 && setProp("first-child");
  i === length - 1 && setProp("last-child");
  (i === 0 || i % 2 === 0) && setProp("even");
  Math.abs(i % 2) === 1 && setProp("odd");
  setProp("nth-child", i);
  return props;
};

exports.default = loopable;
export default exports;